import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationDTO } from '../dtos/application.dto';
import { RoleDTO } from '../dtos/role.dto';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  constructor(private readonly httpClient: HttpClient) { }

  // Method to request list with all the applications from the BE
  getAllApplications(): Observable<ApplicationDTO[]> {
    return this.httpClient.get<ApplicationDTO[]>(`${environment.platformHost}/platform/applications`);
  }

  // Method to request list of applications relative to an organization
  getApplicationsByOrganizationId(organizationId: string, odataQueryString?: string): Observable<ApplicationDTO[]> {
    return this.httpClient.get<ApplicationDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/applications${odataQueryString}`);
  }

  // Method to request list with all the applications relative to a study form the BE
  getApplicationsByStudyId(organizationId: string, studyId: string): Observable<ApplicationDTO[]> {
    return this.httpClient.get<ApplicationDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/applications`);
  }

  getSelfApplicationsByStudyId(organizationId: string, studyId: string): Observable<ApplicationDTO[]> {
    return this.httpClient.get<ApplicationDTO[]>(`${environment.platformHost}/platform/organization/${organizationId}/study/${studyId}/user/applications`);
  }

  getApplicationRoles(applicationId: string): Observable<RoleDTO[]> {
    return this.httpClient.get<RoleDTO[]>(`${environment.platformHost}/platform/application/${applicationId}/roles`);
  }

  deleteApplicationFromStudy(organizationId: string, studyId: string, applicationId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/application/${applicationId}/study/${studyId}/delete-app-study`, studyId);
  }

  addApplicationToStudy(organizationId: string, studyId: string, applicationId: string): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/organization/${organizationId}/application/${applicationId}/study/${studyId}/add-app-study`, studyId);
  }

  getApplicationSuperuserPages(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.platformHost}/platform/superuser-pages`);
  } 
}
