import { ApplicationsEnum } from '../../../platform/dtos/application.dto';

export class GetApplicationsAction {
  static readonly type = '[Application] [GET] [Applications]';
}

export class GetOrganizationApplicationsAction {
  static readonly type = '[Application] [GET] [Organization Applications]';

  constructor(public organizationID: string, public queryString?: string) {
  }
}

export class GetStudyApplicationsAction {
  static readonly type = '[Application] [GET] [Study Applications]';

  constructor(
    public organizationId: string,
    public studyId: string) {
  }
}

export class GetSelfStudyApplicationsAction {
  static readonly type = '[Application] [GET] [Self Study Applications]';

  constructor(
    public organizationId: string,
    public studyId: string) {
  }
}

export class GetApplicationRolesAction {
  static readonly type = '[Application] [GET] [Roles]';

  constructor(public applicationID: ApplicationsEnum) { }
}

export class AddApplicationToStudyAction {
  static readonly type = '[Application] [POST] [Add Application to study.id]';

  constructor(
    public organizationId: string,
    public studyId: string, 
    public applicationId: string
   ) {
  }
}

export class RemoveApplicationFromStudyAction {
  static readonly type = '[Application] [POST] [Remove Application from study.id]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public applicationId: string) {
  }
}

export class GetApplicationSuperuserPagesAction {
  static readonly type = '[Application] [GET] [Superuser Pages]';
}
