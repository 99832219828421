import {
  DatePipe,
  LOCATION_INITIALIZED,
  registerLocaleData,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { CookieService } from './platform/services/cookie.service';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import * as $ from 'jquery';
import { IdleModule } from './platform/shared/idle/idle.module';
import { LoaderModule } from './platform/shared/loader/loader.module';
import { ExtraOptions, RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NGXS_PLUGINS } from '@ngxs/store';
import { logoutPlugin } from './platform/store/_plugins/logout.plugin';
import { GlobalErrorHandler } from './platform/handlers/global-error.handler';
import { AuthInterceptor } from './platform/interceptors/auth.interceptor';
import { LanguageInterceptor } from './platform/interceptors/language.interceptor';
import { LoaderInterceptor } from './platform/interceptors/loader.interceptor';
import { CoreDateTimePipe } from './platform/pipes/core-date-time/core-date-time.pipe';
import { CoreDatePipe } from './platform/pipes/core-date/core-date.pipe';
import { CoreTimePipe } from './platform/pipes/core-time/core-time.pipe';
import { StoreModule } from './platform/store/store.module';
import { ToastrModule } from 'ngx-toastr';
import { NotificationsService } from './platform/services/notifications.service';
import { QuillModule } from 'ngx-quill';

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const cookieService = injector.get(CookieService);
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
        translate.addLangs(['en', 'it']);
        registerLocaleData(localeIt, 'it');

        const cookieLangValue = cookieService.getCookie(
          environment.languageCookieName
        );

        if (translate.getLangs().includes(cookieLangValue)) {
          translate.use(cookieLangValue).subscribe(
            () => {
              console.info(
                `Successfully initialized '${cookieLangValue}' language.`
              );
            },
            () => {
              console.error(
                `Problem with '${cookieLangValue}' language initialization.`
              );
            },
            () => {
              resolve(null);
            }
          );
        } else {
          resolve(null);
        }
      });
    });
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#cfcfcf',
      text: '#000000',
      link: '#000000',
    },
    button: {
      background: '#f38521',
      text: 'white',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    href: window.location.href.split('#')[0] + '#/privacy-policy',
  },
};

const routerConfig: ExtraOptions = {
  relativeLinkResolution: 'legacy',
  useHash: true,
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IdleModule, // Used in the app.component.html
    LoaderModule, // Used in the app.component.html
    HttpClientModule,
    StoreModule,
    ToastrModule.forRoot({
      timeOut: NotificationsService.TIME_OUT,
      positionClass: NotificationsService.POSITION_CLASS,
    }),
    QuillModule.forRoot({
      // Optional global configuration
      modules: {
        toolbar: [['bold', 'italic', 'underline'], ['custom-button']],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: NGXS_PLUGINS,
      useValue: logoutPlugin,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    DatePipe,
    CoreDatePipe,
    CoreDateTimePipe,
    CoreTimePipe,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
