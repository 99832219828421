import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { DiscussionItemDTO } from "../dtos/meeting-discussion-item.dto";
import { environment } from 'src/environments/environment';
import { DecisionDTO } from "../dtos/decision.dto";
import { IDocument, IStudyDocument } from "src/app/dsmb/interface/document.interface";
import { StringEmpty } from "src/app/platform/utils/global-vars";
import { DocumentHistoryDTO } from "../dtos/document-history.dto";

@Injectable({
  providedIn: 'root'
})
export class DecisionsService {

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  public getDecisions(organizationId: string, studyId: string, filter: string, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<DecisionDTO[]> {
    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      sorting = sorting.replace("meetingTitle", "meetingId");
      sorting = sorting.replace("discussionItemTitle", "discussionItemId");
      sorting = `$orderby=${sorting}, CreatedAt desc`;
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=CreatedAt desc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DecisionDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decisions${odataQueryString}`);
  }

  public getDecisionDetails(organizationId: string, studyId:string, decisionId:string): Observable<DecisionDTO> {
    return this.httpClient.get<DecisionDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decisions/${decisionId}`);
  }

  public getDecisionsCount(organizationId: string, studyId: string, filter: string): Observable<number> {

    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decisions${odataQueryString}`);
  }

  public addDecision(organizationId: string, studyId: string, decision: DecisionDTO): Observable<DecisionDTO> {
    return this.httpClient.post<DecisionDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/add-decision`, decision);
  }

  public getDecisionsByMeeting(organizationId: string, studyId: string, meetingId: string, filter: string, startPage: number, itemsPerPage: number, sorting: string): Observable<DecisionDTO[]> {
    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      sorting = `$orderby=${sorting}, CreatedAt desc`;
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=CreatedAt desc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DecisionDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/decisions${odataQueryString}`);
  }

  public getDecisionsCountByMeeting(organizationId: string, studyId: string, meetingId: string, filter: string): Observable<number> {

    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/decisions${odataQueryString}`);
  }

  public getDecisionsByDiscussionItemId(organizationId: string, studyId: string, discussionItemId: string, filter: string, startPage: number, itemsPerPage: number, sorting: string): Observable<DecisionDTO[]>{
    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      sorting = `$orderby=${sorting}, CreatedAt desc`;
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=CreatedAt desc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<DecisionDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/decisions${odataQueryString}`);
  }

  public getDecisionsCountByDiscussionItem(organizationId: string, studyId: string, discussionItemId: string, filter: string): Observable<number> {

    if (filter) {
      filter += ' and \'d.StudyId\' eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=\'d.StudyId\' eq \'' + studyId + '\'';
    }

    const odataQueryString = `?${filter}&$count=true`;

    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/decisions${odataQueryString}`);
  }

  public editDecisionDetails(organizationId: string, studyId: string, updatedDecision: DecisionDTO) :Observable<DecisionDTO> {
    return this.httpClient.post<DecisionDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${updatedDecision.discussionItemId}/decision/${updatedDecision.id}/edit`, {updatedDecision: updatedDecision});

  }

  public deleteDecision(organizationId: string, studyId: string, decisionId: string, comment: string) {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/${decisionId}/delete`, { comment: comment });
  }

  public uploadDecisionDocument(organizationId: string, studyId: string, documentId: string, versionNo: number, notify: boolean, document: any): Observable<IStudyDocument> {
    let meetingId = document.get('meetingId');
    let discussionItemId = document.get('discussionItemId');
    let decisionid = document.get('decisionId');
    return this.httpClient.post<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/decision/${decisionid}/upload/${documentId}${versionNo > 0 ? `/file/${versionNo}/` : StringEmpty}notify/${notify}`, document)
    .pipe(map( document => {
      return {
       ...(document.lastVersion || document),
       children: document.oldVersions || [],
       open: false,
     };
   }));
  }

  public getDecisionDocuments(organizationId: string, studyId: string, meetingId: string, decisionId: string ): Observable<IStudyDocument[]>{
    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/decision/${decisionId}/documents`)
    .pipe(this.mapDocuments());
  }

  public getDecisionDocumentsByDiscussionItemId(organizationId: string, studyId: string, meetingId: string, discussionItemId: string ): Observable<IStudyDocument[]>{
    return this.httpClient.get<IStudyDocument[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/documents/true`)
    .pipe(this.mapDocuments());
  }
  

  public getDecisionDocumentsCount(organizationId:string, studyId:string, meetingId: string, decisionId:string): Observable<number>{
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/decision/${decisionId}/documents?$count=true`)
  }

  public getDocument(organizationId:string, studyId:string, documentId:string, versionNo:string){
    return this.httpClient.get<IStudyDocument>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/document/${documentId}/version/${versionNo}/view`)
  }

  public deleteDecisionDocument(organizationId:string, studyId: string, meetingId: string, discussionItemId: string, decisionId: string, documentId: string) {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/discussion-item/${discussionItemId}/decision/${decisionId}/document/${documentId}`);
  }

  public getDocumentBase64(organizationId: string, studyId:string, documentId:string, versionNo:string){
    return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/document/${documentId}/version/${versionNo}?isBase64=true`, {
      responseType: 'text'})
    }

    public getAuditTrailDecisionDocument(organizationId: string, studyId: string, documentId: string, startPage: number, itemsPerPage: number, sorting: string){
      const filter = `$filter=Args1 eq '${documentId}' and Operation eq 'download-decision-document' or Operation eq 'view-decision-document' or Operation eq 'upload-decision-document' or Operation eq 'update-decision-document'`;
      const order = `$orderby=Timestamp desc`;
      let odataQueryString = `?${filter}&${order}`;
      const isStudyDocument = false; //ALERT
      const params = [];
  
      if (itemsPerPage !== null && itemsPerPage !== undefined) {
        params.push(`$top=${itemsPerPage}`);
      }
  
      if (startPage !== null && startPage !== undefined) {
        params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
      }
  
      const temp = params.join('&');
      odataQueryString += `&${temp}`;
      return this.httpClient.get<DocumentHistoryDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
    }

    public getAuditTrailDecisionDocumentCount(organizationId:string, studyId:string, documentId:string){
      const filter = `?$filter=Args1 eq '${documentId}' and Operation eq 'download-decision-document' or Operation eq 'view-decision-document' or Operation eq 'upload-decision-document' or Operation eq 'update-decision-document'`;
      const odataQueryString = filter + `&$count=true`;
      const isStudyDocument = false;
      return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/document/${documentId}/history/${isStudyDocument}${odataQueryString}`);
    }

    public downloadDocumentBlob(organizationId: string, studyId:string, documentId:string, versionNo: string) {
      return this.httpClient.get(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/document/${documentId}/version/${versionNo}?isBase64=false`, {
        responseType: 'blob'
      });
    }

    private mapDocuments() {
      return map((documents: any[]) => {
        return documents.map(document => {
          if (!document.oldVersions) {
            console.error('document.oldVersions is empty');
          }
  
          return {
            ...(document.lastVersion || document),
            children: document.oldVersions || [],
            open: false,
          };
        });
      });
    }
}