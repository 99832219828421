import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISidebarItem } from '../interfaces/sidebar-item.interface';
import { UserState } from 'src/app/platform/store/user/user.state';
import { Select } from '@ngxs/store';
import { IUser } from '../model/user.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  @Select(UserState.getLoggedUser) user$: Observable<IUser>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router
  ) { }

  public getSidebar(): Observable<ISidebarItem[]> {
    return this.httpClient.get<ISidebarItem[]>(`${environment.platformHost}/platform/user/sidebar`);
  }

  public getActionRequired(): Observable<Map<string,number>> {
    return this.httpClient.get<Map<string,number>>(`${environment.dsmbHost}/dsmb/user/action-required`)
  }

  public updateDsmbSidebar(sidebar: ISidebarItem[], actionRequired: Map<string,number>) {
    let studyNodes = this.getAllStudyNodes(sidebar);
    for(let study of studyNodes){
      if(actionRequired.hasOwnProperty(study.id)){
        study.numberActionRequired = actionRequired[study.id];
      }
    }
    for(let node of sidebar){
      if(node.type == 'organization'){
        node.numberActionRequired = actionRequired[node.id];
      }
    }
  }

  public getAllStudyNodes(sidebar: ISidebarItem[]) : ISidebarItem[]{
    let studyNodes = [];
    this.searchStudyNodes(sidebar, studyNodes);
    return studyNodes;
  }

  private searchStudyNodes(sidebar: ISidebarItem[], studyNodes = []): void {
    for(let node of sidebar){
      if(node.type == 'study'){
        studyNodes.push(node);
      }
      else{
        this.searchStudyNodes(node.children, studyNodes)
      }
    }
  }
}
