import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, switchMap, tap } from 'rxjs';
import { StringEmpty } from 'src/app/platform/utils/global-vars';
import { environment } from 'src/environments/environment';
import { FilesService } from '../../platform/services/files.service';
import { MeetingId, IConfirmationEmailResponse } from '../../platform/utils/types';
import { CancelMeetingDTO } from '../dtos/cancel-meeting.dto';
import { MeetingAgendaDTO } from '../dtos/meeting-agenda.dto';
import { MeetingAttendeeStatusDTO } from '../dtos/meeting-attendee-status.dto';
import { MeetingAttendeeDTO } from '../dtos/meeting-attendee.dto';
import { MeetingNoteDTO } from '../dtos/meeting-note.dto';
import { MeetingOutcomeDTO } from '../dtos/meeting-outcome.dto';
import { IMeetingResendNotifications } from '../dtos/meeting-resend-notifications';
import { MeetingStatusDTO } from '../dtos/meeting-status.dto';
import { MeetingTemplateConfigurationDTO } from '../dtos/meeting-template-configuration.dto';
import { MeetingSessionDTO } from '../dtos/meeting-session.dto';
import { MeetingDTO } from '../dtos/meeting.dto';
import { NewMeetingAttendeeDTO } from '../dtos/new-meeting-attendees.dto';
import { ScheduleMeetingDTO } from '../dtos/schedule-meeting.dto';
import { MeetingDiscussionItemConfigurationDTO } from '../dtos/meeting-discussion-item-configuration.dto';
import { ActivateMeetingDTO } from '../dtos/activate-meeting';
import { CompleteMeetingDTO } from '../dtos/complete-meeting';
import { MeetingTypeDTO } from '../dtos/meeting-type.dto';
import { RemoveMeetingDTO } from '../dtos/remove-meeting.dto';
import { MeetingStatusEnum } from '../enum/meeting-status.enum';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  private meetingStatus = MeetingStatusEnum;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly filesService: FilesService
  ) { }

  public getMeetings(organizationId: string, studyId: string, filter: string, startPage?: number, itemsPerPage?: number, sorting?: string): Observable<MeetingDTO[]> {
    // Add studyId to filter
    if (filter) {
      filter += ' and StudyId eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=StudyId eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      params.push(`$top=${itemsPerPage}`);
    }

    if (startPage !== null && startPage !== undefined) {
      params.push(`$skip=${(startPage - 1) * itemsPerPage}`);
    }

    if (sorting !== null && sorting !== undefined) {
      if (sorting === 'creation desc'){
        sorting = '$orderby=CreatedAt desc';
      }
      else if (sorting === 'creation asc'){
        sorting = '$orderby=CreatedAt desc';
      }
      else if (sorting === 'start desc') {
        sorting = '$orderby=Start desc';
      }
      else if (sorting === 'start asc') {
        sorting = '$orderby=Start asc';
      }
      else if (sorting === 'end desc') {
        sorting = '$orderby=End desc';
      }
      else if (sorting === 'end asc') {
        sorting = '$orderby=End asc';
      }
      else {
        sorting = `$orderby=${sorting}, Start desc`;
      }
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=Start desc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings${odataQueryString}`);
  }

  public getListMeetings(organizationId: string, studyId: string, filter: string, sorting?: string): Observable<MeetingDTO[]> {
    // Add studyId to filter
    if (filter) {
      filter += ' and StudyId eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=StudyId eq \'' + studyId + '\'';
    }

    let odataQueryString = filter;

    const params = [];

    if (sorting !== null && sorting !== undefined) {
      if (sorting === 'creation desc'){
        sorting = '$orderby=CreatedAt desc';
      }
      else if (sorting === 'creation asc'){
        sorting = '$orderby=CreatedAt desc';
      }
      else if (sorting === 'start desc') {
        sorting = '$orderby=Start desc';
      }
      else if (sorting === 'start asc') {
        sorting = '$orderby=Start asc';
      }
      else if (sorting === 'end desc') {
        sorting = '$orderby=End desc';
      }
      else if (sorting === 'end asc') {
        sorting = '$orderby=End asc';
      }
      else {
        sorting = `$orderby=${sorting}, Start desc`;
      }
    }
    else {
      // by default sort meeting by start descending
      sorting = `$orderby=Start desc`;
    }

    params.push(sorting);

    let temp = params.join('&');
    if (temp.length && !temp.startsWith('&')) {
      temp = '&' + temp;
    }
    odataQueryString += temp;

    if (odataQueryString.length) {
      odataQueryString = `?${odataQueryString}`;
    }

    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings-list${odataQueryString}`);
  }

  public getMeetingsCount(organizationId:string, studyId: string, filter: string): Observable<number> {
    // Add studyId to filter
    if (filter) {
      filter += ' and StudyId eq \'' + studyId + '\'';
    }
    else {
      filter = '$filter=StudyId eq \'' + studyId + '\'';
    }
    const odataQueryString = `?${filter}&$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings${odataQueryString}`);
  }

  getMeetingsByOrganization(organizationId, filter, startPage, itemsPerPage, sorting): Observable<MeetingDTO[]>{
    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/meetings`);
  }

  public getUpcomingMeetings(organizationId:string, studyId: string, topCondition: string): Observable<MeetingDTO[]> {
    const today = moment().utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString().split('.')[0] + 'Z'; // 2022-01-24T00:00:00.000Z
    const odataQueryString = '?$filter=StudyId eq \'' + studyId + '\' and Start ge ' + today + ' or Start eq null and Status ne \'' + this.meetingStatus.REMOVED + '\'' + topCondition + '&$orderby=Start desc';
    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings${odataQueryString}`);
  }

  public getUpcomingMeetingsCount(organizationId:string, studyId: string): Observable<number> {
    const today = moment().utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString().split('.')[0] + 'Z'; // 2022-01-24T00:00:00.000Z
    const odataQueryString = '?$filter=StudyId eq \'' + studyId + '\' and Start ge ' + today + ' or Start eq null and Status ne \'' + this.meetingStatus.REMOVED + '\'&$top=5&$orderby=Start desc';
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/upcoming-meetings-count${odataQueryString}`);
  }

  public getPastMeetings(organizationId:string, studyId: string): Observable<MeetingDTO[]> {
    const today = moment().utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString().split('.')[0] + 'Z'; // 2022-01-24T00:00:00.000Z
    const odataQueryString = '?$filter=StudyId eq \'' + studyId + '\' and End le ' + today + ' and Status ne \'' + this.meetingStatus.REMOVED + '\'&$top=5&$orderby=Start desc';
    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings${odataQueryString}`);
  }

  public getCompletedMeetingsCount(organizationId:string, studyId: string): Observable<number> {
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings/true?$filter=Status eq \'${this.meetingStatus.COMPLETED}\' and StudyId eq \'${studyId}\'`);
  }

  public getUpcomingMeetings4Organization(organizationId: string, timeCondition: string): Observable<MeetingDTO[]> {
    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/${timeCondition}-meetings`)
  }

  public getPastMeetings4Organization(organizationId: string, timeCondition: string): Observable<MeetingDTO[]> {
    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/${timeCondition}-meetings`)
  }

  public getMeetingsAssociatedWithDiscussionItems(organizationId: string, studyId: string, filter: string): Observable<MeetingDTO[]> {
    let odataQueryString = StringEmpty;

    if (filter) {
      odataQueryString = `?${filter}`;
    }

    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-items-meetings${odataQueryString}`);
  }

  public getMeetingsAssociatedWithDecisions(organizationId: string, studyId: string, filter: string): Observable<MeetingDTO[]> {
    let odataQueryString = StringEmpty;

    if (filter) {
      odataQueryString = `?${filter}`;
    }

    return this.httpClient.get<MeetingDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decisions-meetings${odataQueryString}`);
  }

  public getMeeting(organizationId: string, studyId: string, meetingId: MeetingId): Observable<MeetingDTO> {
    return this.httpClient.get<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}`);
  }

  public getMeetingSessions(): Observable<MeetingSessionDTO[]> {
    return this.httpClient.get<MeetingSessionDTO[]>(`${environment.dsmbHost}/dsmb/meeting-sessions`);
  }

  public getMeetingStatuses(): Observable<MeetingStatusDTO[]> {
    return this.httpClient.get<MeetingStatusDTO[]>(`${environment.dsmbHost}/dsmb/meeting-statuses`);
  }

  public getMeetingAgendaTemplate(organizationId: string, studyId: string) {
    return this.httpClient.get<Blob>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/agendaDocumentSample`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  public getMeetingOutcomeTemplate(organizationId:string, studyId: string) {
    return this.httpClient.get<Blob>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/outcomeDocumentSample`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  public addMeeting(organizationId: string, studyId: string, meeting: MeetingDTO): Observable<MeetingDTO> {
    return this.httpClient.post<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/add-meeting`, meeting);
  }

  public updateMeeting(organizationId:string, studyId: string, meetingId: MeetingId, meeting: MeetingDTO): Observable<MeetingDTO> {
    return this.httpClient.post<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/update`, meeting);
  }

  public updateMeetingLink(organizationId:string, studyId: string, meetingId: string, payload: any): Observable<MeetingDTO> {
    return this.httpClient.post<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/update/meeting-link`, payload);
  }

  public addMeetingNote(organizationId:string, studyId: string, meetingId: MeetingId, newItem: any): Observable<any> {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/note`, newItem);
  }

  public getMeetingNotes(organizationId:string,studyId: string, meetingId: MeetingId): Observable<MeetingNoteDTO[]> {
    return this.httpClient.get<MeetingNoteDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/notes`);
  }

  public getMeetingAttendeesStatuses(): Observable<MeetingAttendeeStatusDTO[]> {
    return this.httpClient.get<MeetingAttendeeStatusDTO[]>(`${environment.dsmbHost}/dsmb/attendees-invite-statuses`);
  }

  public downloadMeetingSummary(organizationId:string, studyId: string, meetingId: MeetingId) {
    return this.httpClient.get<Blob>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/summary`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    })
      .pipe(
        switchMap(response => this.filesService.download(response))
      );
  }

  public getMeetingAttendees(organizationId: string, studyId: string, meetingId: MeetingId | string): Observable<MeetingAttendeeDTO[]> {
    return this.httpClient.get<MeetingAttendeeDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/attendees`);
  }

  public getPossibleMeetingAttendees(organizationId: string, studyId: string, meetingId: MeetingId): Observable<MeetingAttendeeDTO[]> {
    return this.httpClient.get<MeetingAttendeeDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/possible-attendees`);
  }

  public getPossibleNewMeetingAttendees(organizationId: string, studyId: string, meetingSession: string): Observable<NewMeetingAttendeeDTO> {
    return this.httpClient.get<NewMeetingAttendeeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/new-meeting/${meetingSession}/possible-attendees`);
  }

  public updateMeetingAttendees(organizationId: string, studyId: string, meetingId: MeetingId, newAttendees: MeetingAttendeeDTO[]): Observable<any> {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/update-attendees`, newAttendees);
  }

  public activateMeeting(organizationId: string, studyId: string, meetingId: MeetingId, meeting: ActivateMeetingDTO): Observable<any> {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/activate`, meeting);
  }

  public completeMeeting(organizationId: string, studyId: string, meetingId: MeetingId, meeting: CompleteMeetingDTO): Observable<any> {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/complete`, meeting);
  }

  public scheduleMeeting(organizationId:string, studyId: string, meetingId: MeetingId, meeting: ScheduleMeetingDTO): Observable<ScheduleMeetingDTO> {
    return this.httpClient.post<ScheduleMeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/schedule`, meeting);
  }

  public confirmMeeting(organizationId:string, studyId: string, meetingId: MeetingId, meeting: MeetingDTO): Observable<MeetingDTO> {
    return this.httpClient.post<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/confirm`, meeting);
  }

  public cancelMeeting(organizationId:string, studyId: string, meetingId: MeetingId, meeting: CancelMeetingDTO): Observable<CancelMeetingDTO> {
    return this.httpClient.post<CancelMeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/cancel`, meeting);
  }

  public removeMeeting(organizationId:string, studyId: string, meetingId: MeetingId, meeting: RemoveMeetingDTO): Observable<RemoveMeetingDTO> {
    return this.httpClient.post<RemoveMeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/remove`, meeting);
  }

  public reOpenMeeting(organizationId: string, studyId: string, meetingId: string, meeting: MeetingDTO): Observable<MeetingDTO> {
    return this.httpClient.post<MeetingDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/re-open`, meeting);
  }

  public sendMeetingReminderNotification(organizationId: string, studyId: string, meetingId: string, payload: any) {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/remind`, payload);
  }

  public confirmMeetingEmail(organizationId: string, studyId: string, meetingId: MeetingId, username: string, code: string): Observable<IConfirmationEmailResponse> {
    return this.httpClient.get<IConfirmationEmailResponse>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/user/${username}/set-attendee-invite-status${code}`);
  }

  // Methods relative to the Meeting Agenda --------------------------------------------------------------------------------

  // Method adds a new agenda to the meeting
  public addMeetingAgenda(organizationId: string, studyId: string, meetingId: MeetingId, agenda: MeetingAgendaDTO): Observable<any> {
    return this.httpClient.post<MeetingAttendeeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/agenda`, agenda);
  }

  // Method to get the agenda relative to a meeting
  public getMeetingAgenda(organizationId: string, studyId: string, meetingId: MeetingId): Observable<any> {
    return this.httpClient.get<MeetingAgendaDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/agenda`);
  }

  // Method to update the agenda
  public updateMeetingAgenda(organizationId: string, studyId: string, meetingId: MeetingId, agenda: MeetingAgendaDTO): Observable<any> {
    return this.httpClient.post<MeetingAttendeeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/update-agenda`, agenda);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Methods relative to the Meeting Outcome --------------------------------------------------------------------------------

  // Method adds a new outcome to the meeting
  public addMeetingOutcome(organizationId:string, studyId: string, meetingId: MeetingId, outcome: MeetingOutcomeDTO): Observable<any> {
    return this.httpClient.post<MeetingOutcomeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/outcome`, outcome);
  }

  // Method to get the outcome relative to a meeting
  public getMeetingOutcome(organizationId:string, studyId: string, meetingId: MeetingId): Observable<any> {
    return this.httpClient.get<MeetingOutcomeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/outcome`);
  }

  // Method to update the outcome
  public updateMeetingOutcome(organizationId:string, studyId: string, meetingId: MeetingId, outcome: MeetingOutcomeDTO): Observable<any> {
    return this.httpClient.post<MeetingOutcomeDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/outcome/update`, outcome);
  }

  public getMeetingTemplateConfiguration(organizationId:string, studyId: string, meetingSession: string): Observable<MeetingTemplateConfigurationDTO> {
    return this.httpClient.get<MeetingTemplateConfigurationDTO>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings/${meetingSession}/template`);
  }

  public addDefaultMeetingTemplateConfigurations(organizationId: string, studyId: string): Observable<any> {
    return this.httpClient.post(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings/template/default`,{});
  }

  public sendAttendeeEmail(organizationId:string, studyId: string, meetingId: MeetingId, meetingResendNotifications: IMeetingResendNotifications): Observable<void> {
    return this.httpClient.post<void>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/resend-invite`, meetingResendNotifications);
  }

  public removeDefaultMeetingTemplateConfigurations(organizationId: string, studyId: string): Observable<any> {
    return this.httpClient.delete(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meetings/template/default`);
  }

  public getMeetingTypes(organizationId: string, studyId: string): Observable<MeetingTypeDTO[]> {
    return this.httpClient.get<MeetingTypeDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting-types`);
  }

  public getMeetingDiscussionItemConfiguration(organizationId: string, studyId: string, meetingType: string): Observable<MeetingDiscussionItemConfigurationDTO[]> {
    return this.httpClient.get<MeetingDiscussionItemConfigurationDTO[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting-discussion-item/${meetingType}/configuration`);
  }
}
