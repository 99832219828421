import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { firstValueFrom, Observable, tap } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy} from '@ngneat/until-destroy';
import { StudyState } from '../../store/study/study.state';
import { StudyDTO } from '../../../platform/dtos/study.dto';
import { MeetingService } from 'src/app/dsmb/services/meeting.service';
import { TranslateService } from '@ngx-translate/core';
import { DiscussionItemService } from 'src/app/dsmb/services/discussion-item.service';
import { TasksService } from 'src/app/dsmb/services/tasks.service';
import { OrganizationState } from '../../store/organization/organization.state';
import { IOrganization } from '../../interfaces/organization.interface';
import { UserState } from '../../store/user/user.state';
import { IUser } from '../../model/user.model';
import { MeetingDTO } from 'src/app/dsmb/dtos/meeting.dto';
import { MeetingState } from 'src/app/dsmb/store/meeting/meeting.state';
import { DiscussionItemsState } from 'src/app/dsmb/store/discussion-items/discussion-items.state';
import { DiscussionItemDTO } from 'src/app/dsmb/dtos/meeting-discussion-item.dto';
import { TasksState } from 'src/app/dsmb/store/tasks/tasks.state';
import { IUserTask } from 'src/app/dsmb/interface/task.interface';
import { StringEmpty } from '../../utils/global-vars';
import { StudyService } from '../../services/study.service';
import { ApplicationState } from '../../store/application/application.state';
import { GetApplicationSuperuserPagesAction } from '../../store/application/application.actions';

export interface Breadcrumb {
  label: string;
  url?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Select(StudyState.getStudies) public readonly selfStudies$: Observable<StudyDTO[]>;
  @Select(OrganizationState.getSelfOrganizations) public readonly selfOrganizations$: Observable<IOrganization[]>;
  @Select(UserState.getLoggedUser) user$: Observable<IUser>;
  @Select(MeetingState.getMeeting) public readonly meeting$: Observable<MeetingDTO>;
  @Select(DiscussionItemsState.getDiscussionItem) public readonly discussionItem$: Observable<DiscussionItemDTO>;
  @Select(TasksState.getUserTaskDetails) public readonly task$: Observable<IUserTask>;
  @Select(UserState.getLoggedUser) public readonly loggedUser$: Observable<IUser>;
  
  public breadcrumbs: Breadcrumb[] = [];
  public consecutiveUrl: string[] = [];
  public isSuperuser: boolean = false;
  public lastViewOperation: string = StringEmpty;

  // This list of string represents the view operation to access thes pages that only the superuser can access
  // This list is used to distinguish between a super user page in order to do some additional processing to the breadcrumbs information
  @Select(ApplicationState.getApplicationSuperuserPages) public readonly applicationSuperuserPages$: Observable<string[]>;
  public applicationSuperuserPages: string[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly meetingService: MeetingService,
    private readonly discussionItemService: DiscussionItemService,
    private readonly taskService: TasksService,
    private readonly studyService: StudyService,
    private readonly store: Store
  ) {
  }

  public ngOnInit(): void {
    this.applicationSuperuserPages$.pipe(tap(pages => {
      if (pages.length == 0) {
        this.store.dispatch(GetApplicationSuperuserPagesAction);
      }
      else {
        this.applicationSuperuserPages = pages;
        this.buildBreadcrumbs();
      }
    })).subscribe();
  }
  
  private async buildBreadcrumbs() {
    this.breadcrumbs = [];
    this.consecutiveUrl = [];

    var childRoute = this.activatedRoute.root;

    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;

      this.lastViewOperation = childRoute?.snapshot.data?.operation;

      const dataBreadcrumb = childRoute?.snapshot.data?.breadcrumbs;
      const params = childRoute?.snapshot.params;
      if (dataBreadcrumb) {
        let breadcrumb = dataBreadcrumb as Breadcrumb
        
        // Get the label for the breadcrumb
        const label = await this.getLabel(breadcrumb, params);

        // Get the url piece for the breadcrub
        let url = await this.getUrl(breadcrumb.url, params);

        // Construct the url by joining the prevuous url path with the current one
        let completeRelativeUrl = `${this.consecutiveUrl.join('/')}/${url}`

        // Add them only if they are not already present
        if (label && !this.breadcrumbs.find(b => b.label == label)) {
          this.breadcrumbs.push({ label, url: completeRelativeUrl });
        }

        if (url && !this.consecutiveUrl.find(u => u == url)) {
          this.consecutiveUrl.push(url);
        }
      }
    }

    // Change the label of third segement of breadcrumbs if necessary
    // If it is not a superuser landing page, if it does not end with 'users' (meaning the Organization users page)
    // and if it ends with the "platform.study-details.breadcrumb"
    if (this.breadcrumbs[2]
      && !this.applicationSuperuserPages.includes(this.lastViewOperation)
      && this.breadcrumbs[2].url.split('/')[this.breadcrumbs[2].url.split('/').length -1] !== 'users'
      && this.breadcrumbs[2].label === this.translateService.instant("platform.study-details.breadcrumb")) {
        // Then we need to update the label of the breadcrumb with the actual study name, we are adding the check and in case call directly the service
        // since that for superuser the call to fetch all the studies in the user.resolver is not executed
        const studyId = this.breadcrumbs[2].url.split('/')[this.breadcrumbs[2].url.split('/').length -1];
        let study = (await firstValueFrom<StudyDTO[]>(this.selfStudies$)).find(s => s.id == studyId);
        if (!study) {
          study = (await firstValueFrom<StudyDTO[]>(this.studyService.getSelfStudies())).find(s => s.id == studyId);
        }
        this.breadcrumbs[2].label = study?.name;
    }
  }


  // Reconstruct the breadcrumbs in case it is not a superuser and superuser page
  // The following piece of code is necessary beacuse different user role need to access different pages from their breadcrumbs
  // so we are going to differentiate beetween superuser and the rest of user role. 
  // The breadcrumbs are done for the superuser so if current page is not a superuser page additional processing is necessary to correct it
  // for not superuser role. 
  // This is necessary since a user could be at the same time superuser and something else 
  // So in case the current page is not one of the superusers we are going to change information inside the breadcrumbs
  public changeBreadcrumbUrl(breadcrumb: Breadcrumb, index: number): string {
    if (!this.applicationSuperuserPages.includes(this.lastViewOperation)) {
      switch (index)  {
        case 0:
          // The first entry in the breadcrumbs should be select-organization for the non superuser
          return breadcrumb.url.replace("/organizations", "/select-organization");
        case 1:
          // The second entry should be the select-study so we are going to add it to the end of the url (organizations/organizationId)
          return  breadcrumb.url + "/select-study";
        case 2:
          // Then for the third entry, and only if it does not end with users since it means it is the organization users for administrator roles,
          // should be redirect to the study dashboard page so we are going to add it to the url (organizations/organizationId/study/studyId)
          if (breadcrumb.url.split('/')[breadcrumb.url.split('/').length -1] !== 'users') {
            return breadcrumb.url + "/dashboard";
          }
      }
    }

    return breadcrumb.url;
  }

  private async getLabel(breadcrumb: Breadcrumb, params: Params): Promise<string> {
    let label = breadcrumb.label;
    if (label === ":organizationId") {
      const organization = (await firstValueFrom<IOrganization[]>(this.selfOrganizations$)).find(o => o.id == params.organizationId);
      label = organization?.name;
    } else if (label === ":studyId") {
      const study = (await firstValueFrom<StudyDTO[]>(this.selfStudies$)).find(s => s.id == params.studyId);
      label = study?.name;
    } else if (label === ":meetingId") {
      let meeting = (await firstValueFrom<MeetingDTO>(this.meeting$));
      if (!meeting) {
        meeting = await firstValueFrom(this.meetingService.getMeeting(params.organizationId, params.studyId, params.meetingId));
      }
      label = meeting?.title;
    } else if (label === ":discussionItemId") {
      let discussionItem = (await firstValueFrom<DiscussionItemDTO>(this.discussionItem$));
      if (!discussionItem) {
        discussionItem = await firstValueFrom<DiscussionItemDTO>(this.discussionItemService.getDiscussionItemDetail(params.organizationId, params.studyId, params.discussionItemId));
      }
      label = discussionItem?.title;
    } else if (label === ":taskId") {
      let task = await firstValueFrom<IUserTask>(this.task$);
      if (!task) {
        task = await firstValueFrom(this.taskService.getUserTaskDetailsAction(params.organizationId, params.studyId, params.taskId));
      }
      label = task.task.title; 
    } else if (label) {
      label = this.translateService.instant(label);
    }
    return label;
  }

  private async getUrl(url: string, params: Params): Promise<string> {
    return url?.replace(":username", params.username)
               .replace(":applicationId", params.applicationId)
               .replace(":roleId", params.roleId)
               .replace(":organizationId", params.organizationId)
               .replace(":studyId", params.studyId)
               .replace(":meetingId", params.meetingId)
               .replace(":discussionItemId", params.discussionItemId)
               .replace(":decisionId", params.decisionId)
               .replace(":taskId", params.taskId);
  }
}